var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "head" },
        [
          _c(
            "el-form",
            {
              ref: "queryForm",
              attrs: {
                model: _vm.queryParams,
                inline: true,
                "label-width": "85px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: { display: "block" },
                  attrs: { label: "报错类型:", prop: "type" },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { size: "small" },
                      on: { input: _vm.typeChange },
                      model: {
                        value: _vm.queryParams.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "type", $$v)
                        },
                        expression: "queryParams.type",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "" } }, [
                        _vm._v("全部"),
                      ]),
                      _vm._l(_vm.dict.type.sys_error_type, function (item) {
                        return _c(
                          "el-radio-button",
                          { key: item.value, attrs: { label: item.value } },
                          [_vm._v(_vm._s(item.label))]
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "用户ID:", prop: "userId" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入用户ID",
                      clearable: "",
                      size: "small",
                    },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handleQuery($event)
                      },
                    },
                    model: {
                      value: _vm.queryParams.userId,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.queryParams,
                          "userId",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "queryParams.userId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "错误ID:", prop: "errorId" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入错误ID",
                      clearable: "",
                      size: "small",
                    },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handleQuery($event)
                      },
                    },
                    model: {
                      value: _vm.queryParams.errorId,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.queryParams,
                          "errorId",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "queryParams.errorId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "时间区间:" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { "font-weight": "bold" },
                    attrs: {
                      type: "datetimerange",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      "range-separator": "至",
                      "start-placeholder": "开始日期时间",
                      "end-placeholder": "结束日期时间",
                      "default-time": ["00:00:00", "23:59:59"],
                      "picker-options": _vm.pickerOptionsStart,
                    },
                    model: {
                      value: _vm.queryTimeRange,
                      callback: function ($$v) {
                        _vm.queryTimeRange = $$v
                      },
                      expression: "queryTimeRange",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "错误级别:" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "select-project",
                      attrs: { placeholder: "请选择错误级别", clearable: "" },
                      model: {
                        value: _vm.queryParams.level,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "level", $$v)
                        },
                        expression: "queryParams.level",
                      },
                    },
                    _vm._l(_vm.dict.type.error_level, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "el-icon-search",
                        size: "mini",
                      },
                      on: { click: _vm.handleQuery },
                    },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-refresh", size: "mini" },
                      on: { click: _vm.resetQuery },
                    },
                    [_vm._v("重置")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "el-row",
            { staticClass: "mb8", attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 1.5 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "danger",
                        plain: "",
                        icon: "el-icon-delete",
                        size: "mini",
                      },
                      on: { click: _vm.handleDelete },
                    },
                    [_vm._v("删除")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { data: _vm.list },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", {
            attrs: { type: "selection", width: "55", align: "center" },
          }),
          _c("el-table-column", {
            attrs: { label: "错误摘要", "min-width": "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "span",
                      {
                        staticClass: "operate-btn",
                        on: {
                          click: function ($event) {
                            return _vm.handleDetail(scope.row)
                          },
                        },
                      },
                      [
                        scope.row.level === "high"
                          ? _c("span", [
                              _c("span", [
                                _vm._v(
                                  "【" +
                                    _vm._s(
                                      _vm.selectDictLabel(
                                        _vm.dict.type.park_path_business,
                                        JSON.parse(scope.row.message).path
                                      )
                                    ) +
                                    "】"
                                ),
                              ]),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    JSON.parse(scope.row.message).mitoMessage +
                                      "-" +
                                      JSON.parse(scope.row.message).parkName +
                                      "-" +
                                      JSON.parse(scope.row.message)
                                        .inPlateNumber
                                  )
                                ),
                              ]),
                            ])
                          : scope.row.type === "JUMP"
                          ? _c("span", [
                              _c("span", [
                                _vm._v(
                                  "【跳转Pay收银台页面异常】" +
                                    _vm._s(
                                      JSON.parse(scope.row.message).parkName +
                                        "-" +
                                        JSON.parse(scope.row.message)
                                          .plateNumber
                                    )
                                ),
                              ]),
                            ])
                          : _c("span", [_vm._v(_vm._s(scope.row.message))]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", { attrs: { label: "错误名称", prop: "name" } }),
          _c("el-table-column", {
            attrs: { label: "报错类型" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.selectDictLabel(
                            _vm.dict.type.sys_error_type,
                            scope.row.type
                          ) || "-"
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "报错页面", prop: "url", "min-width": "300" },
          }),
          _c("el-table-column", {
            attrs: { label: "报错时间", width: "160" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.parseTime(+scope.row.time))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "错误级别", prop: "level", width: "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { class: _vm.getErrorClass(scope.row.level) }, [
                      _vm._v(
                        _vm._s(
                          _vm.selectDictLabel(
                            _vm.dict.type.error_level,
                            scope.row.level
                          ) || "-"
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", { attrs: { label: "用户ID", prop: "userId" } }),
          _c("el-table-column", {
            attrs: { label: "错误ID", prop: "errorId" },
          }),
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.queryParams.pageNum,
          limit: _vm.queryParams.pageSize,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.queryParams, "pageNum", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryParams, "pageSize", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: { title: "删除异常日志", visible: _vm.dialogFormVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { ref: "delForm", attrs: { model: _vm.delForm, rules: _vm.rules } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "时间区间", prop: "timeRange" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { "font-weight": "bold" },
                    attrs: {
                      type: "datetimerange",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      "default-time": ["00:00:00", "23:59:59"],
                      "range-separator": "至",
                      "start-placeholder": "开始日期时间",
                      "end-placeholder": "结束日期时间",
                      "picker-options": _vm.pickerOptionsStart,
                    },
                    model: {
                      value: _vm.delForm.timeRange,
                      callback: function ($$v) {
                        _vm.$set(_vm.delForm, "timeRange", $$v)
                      },
                      expression: "delForm.timeRange",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.cancelFormDelete } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleFormDelete },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }