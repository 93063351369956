import request from '@/utils/request'

// 异常列表查询
export function listError(data) {
  return request({
    url: '/error/info/list?pageNum=' + data.pageNum + '&pageSize=' + data.pageSize,
    method: 'post',
    data: data
  })
}

// 查询异常详细
export function getReportDetail(data) {
  return request({
    url: '/error/info/detail',
    method: 'post',
    data: data
  })
}

// 删除异常日志
export function delError(data) {
  return request({
    url: '/error/info/delete',
    method: 'post',
    data: data
  })
}
