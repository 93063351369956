<template>
  <div class="app-container" v-loading.lock="fullscreenLoading">
    <div class="head">
      <div class="back">
        <span class="header-back">
          <i class="el-icon-arrow-left" @click="backPage" />
        </span>
        <span class="back-title">错误详情</span>
      </div>
      <div class="info">
        <span class="msg">
          错误摘要:
          <span v-if="info.level === 'high'">
            <span>{{ logMessage.mitoMessage }}</span>
          </span>
          <span v-else-if="info.type === 'JUMP'">
            <span>{{'跳转Pay收银台页面异常：' + logMessage.payUrl }}</span>
          </span>
          <span v-else>{{ info.message }}</span>
        </span>
        <div class="txt">
          <span class="error">{{ info.name }}</span>
          <span class="time">{{ parseTime(info.time) }}</span>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="content-title">错误明细</div>
      <div class="detail-content">
        <div class="info-box">
          <div class="sub-title" v-if="info.level === 'high'">上报信息</div>
          <el-row v-if="info.level === 'high'">
            <el-col :span="12">
              <div class="sub-info">
                <span>业务场景: </span>
                <span>{{ selectDictLabel(dict.type.park_path_business, logMessage.path) }}</span>
              </div>
              <div class="sub-info">
                <span>车场名称: </span>
                <span>{{ logMessage.parkName || "-" }}</span>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="sub-info">
                <span>车牌号码: </span>
                <span>{{ logMessage.inPlateNumber || "-" }}</span>
              </div>
              <div class="sub-info">
                <span>错误信息: </span>
                <span>{{ logMessage.mitoMessage || "-" }}</span>
              </div>
            </el-col>
          </el-row>
          <div class="sub-title">基本信息</div>
          <el-row>
            <el-col :span="12">
              <div class="sub-info" v-if="info.type === 'JUMP'">
                <span>车场名称: </span>
                <span>{{ logMessage.parkName || "-" }}</span>
              </div>
              <div class="sub-info" v-if="info.type === 'JUMP'">
                <span>车牌号码: </span>
                <span>{{ logMessage.plateNumber || "-" }}</span>
              </div>
              <div class="sub-info">
                <span>用户ID: <span>
                </span>{{ info.userId || "-" }}</span>
              </div>
              <div class="sub-info">
                <span>错误ID: <span>
                </span>{{ info.errorId || "-" }}</span>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="sub-info">
                <span>页面URL: </span>
                <span>{{ info.url }}</span>
              </div>
              <div class="sub-info">
                <span>浏览器信息: </span>
                <span>{{ deviceInfo.browserInfo || "-" }}</span>
              </div>
            </el-col>
          </el-row>
          <div class="sub-title" v-if="JSON.stringify(deviceInfo) !== '{}'">设备信息</div>
          <el-row v-if="JSON.stringify(deviceInfo) !== '{}'">
            <el-col :span="12">
              <div class="sub-info">
                <span>设备品牌: </span
                ><span>{{ deviceInfo.deviceType || `${ (deviceInfo.brand || '-') + ' ' + (deviceInfo.model || '-') }` }}</span>
              </div>
              <div class="sub-info">
                <span>操作系统: </span>
                <span>{{ deviceInfo.system || `${ (deviceInfo.OS || '-') + ' ' + (deviceInfo.OSVersion || '-') }` }}</span>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="sub-info">
                <span>屏幕宽高: </span>
                <span>{{ `${ deviceInfo.clientWidth || '-' } * ${ deviceInfo.clientHeight || "-"}` }}</span>
              </div>
              <div class="sub-info" v-if="deviceInfo.system">
                <span>联网类型: </span>
                <span>{{ deviceInfo.netType || "-" }}</span>
              </div>
            </el-col>
          </el-row>
          <div class="sub-title" v-if="info.type === 'HTTP'">请求信息</div>
          <el-row v-if="info.type === 'HTTP'">
            <el-col :span="12">
              <div class="sub-info">
                <span>请求类型: </span>
                <span>{{ `${ info.httpType || "-" } ${ info.method || "-" } ${ info.status || "-" }` }}</span>
              </div>
              <div class="sub-info">
                <span>接口URL: </span>
                <span>{{ info.path || "-" }}</span>
              </div>
              <div class="sub-info">
                <span>请求耗时: </span>
                <span>{{ info.elapsedTime }}ms</span>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="sub-info">
                <span>入参: </span>
                <span>{{ info.requestParam || "-" }}</span>
              </div>
              <div class="sub-info">
                <span>返参: </span>
                <span>{{ info.responseParam || "-" }}</span>
              </div>
            </el-col>
          </el-row>

          <div class="sub-title" v-if="info.type === 'LOG' && logMessage.type === 'HTTP'">请求信息</div>
          <el-row v-if="info.type === 'LOG' && logMessage.type === 'HTTP'">
            <el-col :span="12">
              <div class="sub-info">
                <span>请求类型: </span>
                <span>{{ `${ logMessage.method || "-" } ${ logMessage.type === 'HTTP' ? 200 : "-"}` }}</span>
              </div>
              <div class="sub-info">
                <span>接口URL: </span>
                <span>{{ logMessage.path || "-" }}</span>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="sub-info">
                <span>入参: </span>
                <span>{{ logMessage.requestParam || "-" }}</span>
              </div>
              <div class="sub-info">
                <span>返参: </span>
                <span>{{ logMessage.responseParam || "-" }}</span>
              </div>
            </el-col>
          </el-row>
        </div>

        <div
          class="content-title"
          style="margin-bottom: 5px"
          v-if="info.type === 'JAVASCRIPT' || info.type === 'VUE'"
        >
          <span >错误堆栈</span>
          <span class="special operate-btn" @click="handleImportSourceCode">
            <i></i>
            源码解析（SourceMap映射）
          </span>
        </div>
        <div class="js-stack" v-if="info.type === 'JAVASCRIPT'">
          <div class="item" v-for="(item, index) in stack" :key="index">
            <span class="item-title">第{{ index + 1 }}层</span>
            <div class="stack-content">
              <span>错误信息: {{ item.func }}</span>
              <span>文件路径: {{ item.url }}</span>
              <span>错误参数: {{ item.args }}</span>
              <span>代码行数: {{ item.line }}</span>
              <span>代码列数: {{ item.column }}</span>
            </div>
          </div>
        </div>
        <div class="vue-stack" v-highlight v-if="info.type === 'VUE'">
          <pre>
            <code>{{info.stack}}</code>
          </pre>
        </div>

        <div class="content-title" style="margin-bottom: 10px">报错轨迹</div>
        <el-timeline class="breadcrumb-timeline">
          <el-timeline-item
            class="item"
            size="large"
            :timestamp="parseTime(item.time)"
            placement="top"
            v-for="(item, index) in breadcrumb"
            :key="index"
            :icon="levelMap[item.level].icon"
            :color="levelMap[item.level].color"
          >
            <el-card class="breadcrumb">
              <div class="breadcrumb-title">
                <span class="type-txt">{{ selectDictLabel(dict.type.browser_breadcrumb_types, item.type) || selectDictLabel(dict.type.wx_breadcrumb_types, item.type) || item.type || '-' }}</span>
                <span
                  class="current-error"
                  v-if="
                    item.level === 'error' &&
                    +item.data.errorId === +info.errorId && +item.data.time === +info.time
                  "
                >
                  <span class="styles_pulse__9HoxO"></span>
                  <span class="txt">当前报错位置</span>
                </span>
              </div>
              <div class="stack-content" v-if="item.data">
                <div v-highlight>
                  <pre v-if="new RegExp(/<[^>]+>/).test(item.data)">
                    <code class="html">{{ item.data }}</code>
                  </pre>
                  <pre v-else>
                    <code>{{ item.data }}</code>
                  </pre>
                </div>
              </div>
            </el-card>
          </el-timeline-item>
        </el-timeline>
      </div>
    </div>

    <!-- 用户导入对话框 -->
    <el-dialog class="upload-dialog" :title="upload.title" :visible.sync="upload.open" width="900px" append-to-body>
      <div class="stack" v-highlight v-if="info.type === 'VUE'">
        <pre>
          <code>{{info.stack}}</code>
        </pre>
      </div>
      <el-upload
        ref="upload"
        :limit="1"
        accept=".map"
        :headers="upload.headers"
        :action="upload.url"
        :disabled="upload.isUploading"
        :on-progress="handleFileUploadProgress"
        :on-success="handleFileSuccess"
        :auto-upload="true"
        drag
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <span>仅允许导入.map文件</span>
      </el-upload>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleParse">确 定</el-button>
        <el-button @click="upload.open = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getReportDetail } from "@/api/analysis";
import { getToken } from '@/utils/auth'

export default {
  name: 'ErrorDetail',
  dicts: ['browser_breadcrumb_types', 'wx_breadcrumb_types', 'park_path_business'],
  data() {
    return {
      fullscreenLoading: false,
      info: {},
      breadcrumb: [],
      deviceInfo: {},
      levelMap: {
        info: {
          icon: "el-icon-info",
          color: "#67C23A",
        },
        error: {
          icon: "el-icon-error",
          color: "#F56C6C",
        },
      },
      // 自定义异常
      logMessage: {},
      // 用户导入参数
      upload: {
        // 是否显示弹出层（用户导入）
        open: false,
        // 弹出层标题（用户导入）
        title: "",
        // 是否禁用上传
        isUploading: false,
        // 是否更新已经存在的用户数据
        updateSupport: 0,
        // 设置上传的请求头部
        headers: { Authorization: "Bearer " + getToken() },
        // 上传的地址
        url: process.env.VUE_APP_BASE_API + "/common/upload"
      },
    };
  },
  created() {
    const id = this.$route.query.id;
    const projectCode = this.$route.query.projectCode;
    this.$cache.local.set("project-code", projectCode);
    this.$store.commit("CHANGE_PROJECTCODE", projectCode);
    this.fullscreenLoading = true;
    getReportDetail({
      id,
      projectCode,
    })
      .then((res) => {
        if (res.code === 200 && res.data) {
          this.info = res.data;
          this.breadcrumb = JSON.parse(res.data.breadcrumb) || [];
          this.deviceInfo = JSON.parse(res.data.deviceInfo) || {};
          this.stack = res.data.stack && JSON.parse(res.data.stack);
          this.logMessage = (res.data.type === 'LOG' || res.data.type === 'JUMP') && JSON.parse(res.data.message);
        }
        this.fullscreenLoading = false;
      })
      .catch(() => {
        this.fullscreenLoading = false;
      });
  },
  methods: {
    backPage() {
      this.$router.push('/analysis/report');
    },
    /** 导入源码按钮操作 */
    handleImportSourceCode() {
      this.upload.title = "SourceMap映射";
      this.upload.open = true;
    },
    // 文件上传中处理
    handleFileUploadProgress(event, file, fileList) {
      this.upload.isUploading = true;
    },
    // 文件上传成功处理
    handleFileSuccess(response, file, fileList) {
      console.log(response, file, fileList, 'response, file, fileList');
      // this.upload.open = false;
      // this.upload.isUploading = false;
      // this.$refs.upload.clearFiles();
      // this.$alert("<div style='overflow: auto;overflow-x: hidden;max-height: 70vh;padding: 10px 20px 0;'>" + response.msg + "</div>", "导入结果", { dangerouslyUseHTMLString: true });
      // this.getList();
    },
    // 提交上传文件
    handleParse() {
      this.$refs.upload.submit();
    }
  },
};
</script>

<style lang="scss" scoped>
.info {
  display: flex;
  flex-direction: column;
  .msg {
    font-size: 16px;
    color: #252934;
    margin-bottom: 10px;
  }
  .txt {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: #333;
  }
}
.content-title {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #333;
  .special {
    font-size: 12px;
    font-weight: normal;
    margin-left: 20px;
  }
}
.detail-content {
  .js-stack {
    display: flex;
    justify-content: space-between;
    .item {
      width: 30%;
      padding: 5px;
      .item-title {
        font-size: 13px;
        font-weight: 500;
        color: #333;
      }
      .stack-content {
        display: flex;
        flex-direction: column;
        line-height: 20px;
        color: #6a798f;
      }
    }
  }
  .info-box {
    margin-bottom: 10px;
    .sub-title {
      font-size: 13px;
      font-weight: 500;
      color: #333;
      margin-top: 8px;
    }
    .sub-info {
      font-size: 12px;
      word-break: break-all;
      color: #6a798f;
      line-height: 25px;
    }
  }
  .stack-content {
    font-size: 12px;
  }
}
.styles_pulse__9HoxO {
  display: inline-block;
  -webkit-animation: styles_pulse-loader__5535u 1.5s ease-out infinite;
  animation: styles_pulse-loader__5535u 1.5s ease-out infinite;
  border-radius: 50%;
  overflow: hidden;
  text-indent: 50px;
  background-color: #f56c6c;
  width: 8px;
  height: 8px;
}

.upload-dialog {
  .stack {
    font-size: 12px;
  }
}

.breadcrumb-timeline {
  .breadcrumb.el-card.is-always-shadow {
    box-shadow: none;
    border: none;
    ::v-deep .el-card__body {
      padding: 0 !important;
    }
  }
  .breadcrumb-title {
    .type-txt {
      font-size: 12px;
      font-weight: 500;
    }
    .current-error {
      margin-left: 10px;
      .txt {
        color: #f56c6c;
        font-size: 12px;
        font-weight: 500;
        margin-left: 10px;
      }
    }
  }
}

@keyframes styles_pulse-loader__5535u {
  0% {
    background-color: #f56c6c;
    box-shadow: 0 0 0 0 rgb(225 170 76 / 0%);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  20% {
    background-color: #f56c6c;
    box-shadow: 0 0 0 4px rgb(225 170 76 / 60%);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  80% {
    background-color: #f56c6c;
    box-shadow: 0 0 0 8px rgb(225 170 76 / 0%);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
</style>
