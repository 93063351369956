<template>
  <div class="app-container">
    <div class="head">
      <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="85px">
        <el-form-item label="报错类型:" prop="type" style="display: block;">
          <el-radio-group v-model="queryParams.type" @input="typeChange" size="small">
            <el-radio-button label="">全部</el-radio-button>
            <el-radio-button v-for="item in dict.type.sys_error_type" :key="item.value" :label="item.value">{{item.label}}</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="用户ID:" prop="userId">
          <el-input
            v-model.trim="queryParams.userId"
            placeholder="请输入用户ID"
            clearable
            size="small"
            @keyup.enter.native="handleQuery"
          />
        </el-form-item>
        <el-form-item label="错误ID:" prop="errorId">
          <el-input
            v-model.trim="queryParams.errorId"
            placeholder="请输入错误ID"
            clearable
            size="small"
            @keyup.enter.native="handleQuery"
          />
        </el-form-item>
        <el-form-item label="时间区间:">
          <el-date-picker v-model="queryTimeRange" type="datetimerange" style="font-weight: bold;" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期时间" end-placeholder="结束日期时间" :default-time="['00:00:00', '23:59:59']" :picker-options="pickerOptionsStart"></el-date-picker>
        </el-form-item>
        <el-form-item label="错误级别:">
          <el-select
            v-model="queryParams.level"
            placeholder="请选择错误级别"
            class="select-project"
            clearable
          >
            <el-option
              v-for="item in dict.type.error_level"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">查询</el-button>
          <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="content">
      <el-row :gutter="10" class="mb8">
        <el-col :span="1.5">
          <el-button
            type="danger"
            plain
            icon="el-icon-delete"
            size="mini"
            @click="handleDelete"
          >删除</el-button>
        </el-col>
      </el-row>
    </div>

    <el-table v-loading="loading" :data="list" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column label="错误摘要" min-width="200">
        <template slot-scope="scope">
          <span class="operate-btn" @click="handleDetail(scope.row)">
            <span v-if="scope.row.level === 'high'">
              <span>【{{ selectDictLabel(dict.type.park_path_business, JSON.parse(scope.row.message).path) }}】</span>
              <span>{{ JSON.parse(scope.row.message).mitoMessage + '-' + JSON.parse(scope.row.message).parkName + '-' + JSON.parse(scope.row.message).inPlateNumber }}</span>
            </span>
            <span v-else-if="scope.row.type === 'JUMP'">
              <span>【跳转Pay收银台页面异常】{{JSON.parse(scope.row.message).parkName + '-' + JSON.parse(scope.row.message).plateNumber }}</span>
            </span>
            <span v-else>{{ scope.row.message }}</span>
          </span>
        </template>
      </el-table-column>
      <el-table-column label="错误名称" prop="name" />
      <el-table-column label="报错类型">
        <template slot-scope="scope">
          <span>{{ selectDictLabel(dict.type.sys_error_type, scope.row.type) || '-' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="报错页面" prop="url" min-width="300"/>
      <el-table-column label="报错时间" width="160">
        <template slot-scope="scope">
          <span>{{ parseTime(+scope.row.time) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="错误级别" prop="level" width="80">
        <template slot-scope="scope">
          <span :class="getErrorClass(scope.row.level)">{{ selectDictLabel(dict.type.error_level, scope.row.level) || '-' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="用户ID" prop="userId" />
      <el-table-column label="错误ID" prop="errorId" />
      <!-- <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            @click="handleUpdate(scope.row)"
          >屏蔽</el-button>
          <el-button
            size="mini"
            type="text"
            @click="handleDelete(scope.row)"
          >解除</el-button>
        </template>
      </el-table-column> -->
    </el-table>

    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />

    <el-dialog title="删除异常日志" :visible.sync="dialogFormVisible">
      <el-form ref="delForm" :model="delForm" :rules="rules">
        <el-form-item label="时间区间" prop="timeRange">
          <el-date-picker v-model="delForm.timeRange" type="datetimerange" style="font-weight: bold;" value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']" range-separator="至" start-placeholder="开始日期时间" end-placeholder="结束日期时间" :picker-options="pickerOptionsStart"></el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelFormDelete">取 消</el-button>
        <el-button type="primary" @click="handleFormDelete">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { listError, delError } from "@/api/analysis";
import { scrollTo } from '@/utils/scroll-to'

export default {
  name: "Report",
  dicts: ['sys_error_type', 'park_path_business', 'error_level'],
  data() {
    return {
      // 遮罩层
      loading: false,
      // 总条数
      total: 0,
      // 表格数据
      list: [],
      // 查询参数
      queryTimeRange: [],
      defaultTime: null,
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        type: "",
        level: ""
      },
      ids: [],
      dialogFormVisible: false,
      // 时间区间删除
      pickerOptionsStart: {
        // 时间不能大于当前时间
        disabledDate: time => {
          return time.getTime() > Date.now()
        },
        selectableRange: '00:00:00 - 23:59:59'
      },
      delForm: {
        timeRange: ''
      },
      rules: {
        timeRange: [
          { required: true, message: '请选择开始时间', trigger: 'change' }
        ],
      }
    };
  },
  computed: {
    ...mapGetters(["projectCode"]),
  },
  watch: {
    projectCode: {
      handler(val) {
        this.queryParams = {
          pageNum: 1,
          pageSize: 10,
          type: "",
          level: ""
        }
        this.getList();
        scrollTo(0, 800);
      },
      immediate: true
    }
  },
  methods: {
    getErrorClass(level) {
      return {
        'critical-class': level === 'critical',
        'error-class': level === 'high',
        'warning-class': level === 'normal',
        'info-class': level === 'low'
      };
    },
    typeChange(val) {
      this.getList();
    },
    /** 查询列表 */
    getList() {
      if (!this.projectCode) {
        this.$message({
          showClose: true,
          message: '请先选择项目',
          type: 'warning'
        });
        return;
      };
      this.loading = true;
      let beginTime = new Date(this.queryTimeRange[0]).getTime();
      let endTime = new Date(this.queryTimeRange[1]).getTime();
      listError({ ...this.queryParams, beginTime: beginTime, endTime: endTime, projectCode: this.projectCode }).then(res => {
        if (!res) return
        this.list = res.rows;
        this.total = res.total;
        this.loading = false;
      }).catch(err => {
        this.loading = false;
      });
    },
    // 表单重置
    // reset() {
    //   this.form = {
    //     projectName: undefined,
    //     projectAddress: undefined
    //   };
    //   this.projectList =[]
    //   this.resetForm("form");
    // },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.queryTimeRange = [];
      this.handleQuery();
    },
    /** 详情按钮操作 */
    handleDetail(row) {
      this.$router.push({
        path: '/analysis/reportDetail',
        query: {
          id: row.id,
          projectCode: row.projectCode
        }
      })
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
    },
    /** 删除按钮操作 */
    handleDelete() {
      if (!this.ids.length) {
        this.dialogFormVisible = true;
        return
      }
      this.$modal.confirm('是否确认删除选择的异常日志？').then(() => {
          return delError({
            projectCode: this.projectCode,
            idList: this.ids
          });
        }).then(() => {
          this.getList();
          this.$modal.msgzhuanSuccess("删除成功");
        }).catch(() => {});
    },
    cancelFormDelete() {
      this.dialogFormVisible = false;
      this.resetForm("delForm");
    },
    handleFormDelete() {
      this.$refs.delForm.validate((valid) => {
        if (valid) {
          let startTime = new Date(this.delForm.timeRange[0]);
          let endTime = new Date(this.delForm.timeRange[1]);
          delError({
            projectCode: this.projectCode,
            startTime: startTime.getTime(),
            endTime: endTime.getTime()
          }).then(() => {
            this.$modal.msgSuccess("删除成功");
            this.dialogFormVisible = false;
            this.resetForm("delForm");
            this.getList();
          }).catch(() => {});
        }
      });
    }
  }
};
</script>

<style lang='scss' scoped>
.critical-class {
  color: darkred;
  font-weight: bold;
}
.error-class {
  color: red;
  font-weight: bold;
}
.warning-class {
  color: orange;
  font-weight: bold;
}
.info-class {
  color: blue;
  font-weight: bold;
}
</style>
