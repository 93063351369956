<template>
  <div class="app-container">
    <div class="home">
      <div class="home-top">
        <el-select v-model="projectCode" placeholder="请选择项目" class="select-project">
          <el-option
            v-for="item in dict.type.project_system_code"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="home-content">
        待定
        <!-- <div class="indicator-container">
          <div v-for="(item, index) in jsErorrList" :key="index" class="indicator-item">
            <div class="top">{{item.label}}</div>
            <div class="content">
              <div class="item">
                <span class="title-t">今日</span>
                <span class="count-t">{{item.today}}</span>
              </div>
              <div class="item">
                <span class="title-y">昨日</span>
                <span class="count-y">{{item.yesterday}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="indicator-container">
          <div v-for="(item, index) in apiErorrList" :key="index" class="indicator-item">
            <div class="top">{{item.label}}</div>
            <div class="content">
              <div class="item">
                <span class="title-t">今日</span>
                <span class="count-t">{{item.today}}</span>
              </div>
              <div class="item">
                <span class="title-y">昨日</span>
                <span class="count-y">{{item.yesterday}}</span>
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <!-- <div class="home-list">
        <div class="list-title">项目列表</div>
        <el-table v-loading="loading" :data="list">
          <el-table-column label="模块名称" align="center" prop="ownerName" />
          <el-table-column label="项目名称" align="center" prop="ownerPhone" />
          <el-table-column label="平台" align="center" prop="projectName" />
          <el-table-column label="JS异常次数" align="center" prop="buildingName" />
          <el-table-column label="JS影响用户数" align="center" prop="ownerAddress" />
          <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="text"
                @click="handleUpdate(scope.row)"
              >编辑</el-button>
            </template>
          </el-table-column>
        </el-table>

        <pagination
          v-show="total>0"
          :total="total"
          :page.sync="queryParams.pageNum"
          :limit.sync="queryParams.pageSize"
          @pagination="getList"
        />
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  dicts: ['project_system_code'],
  data() {
    return {
      jsErorrList: [
        {
          label: 'JS 异常次数',
          today: '588',
          yesterday: '794'
        },
        {
          label: 'JS 影响用户数',
          today: '588',
          yesterday: '794'
        },
        {
          label: 'JS 告警次数',
          today: '588',
          yesterday: '794'
        }
      ],
      apiErorrList: [
        {
          label: 'API 异常次数',
          today: '588',
          yesterday: '794'
        },
        {
          label: 'API 影响用户数',
          today: '588',
          yesterday: '794'
        },
        {
          label: 'API 告警次数',
          today: '588',
          yesterday: '794'
        }
      ],
      loading: false,
      list: [],
      total: 0,
      queryParams: {
        pageNum: 1,
        pageSize: 10
      }
    }
  },
  computed: {
    ...mapGetters(["projectCode"]),
  },
  methods: {
    getList() {

    },
  }
}
</script>

<style lang="scss" scoped>
.home {
  .home-top {
    ::v-deep .el-select {
      margin: 15px 0;
      .el-input__inner {
        width: 260px;
        height: 35px;
        line-height: 35px;
        border-radius: 15px;
      }
    }
  }
  .home-content {
    .indicator-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }
    .indicator-item {
      background: #fff;
      box-shadow: 0 0 6px 0 rgb(0 42 116 / 6%);
      border-radius: 2px;
      padding: 16px 20px;
      margin-right: 16px;
      width: calc(33% - 12px);
      display: flex;
      flex-direction: column;
      &:last-child {
        margin-right: 0px;
      }
      .top {
        font-family: PingFangSC;
        font-weight: 500;
        font-size: 18px;
        color: #252934;
      }
      .content {
        display: flex;
        justify-content: space-around;
        padding-top: 16px;
        .item {
          display: flex;
          flex-direction: column;
          width: 50%;
          .title-t {
            font-weight: 600;
            font-size: 12px;
            color: #242936;
            width: 32px;
            background-color: #edeff4;
            border: 1px solid #e2e5e9;
            border-radius: 2px;
            padding-left: 3px;
            box-sizing: border-box;
          }
          .count-t {
            font-family: AlibabaSans102-Bold;
            font-size: 28px;
            color: #242936;
            letter-spacing: 0;
          }
          .title-y {
            font-weight: 600;
            font-size: 12px;
            color: #fff;
            width: 32px;
            background-color: #3b82fe;
            border-radius: 2px;
            padding-left: 3px;
            box-sizing: border-box;
          }
          .count-y {
            font-family: AlibabaSans102-Bold;
            font-size: 28px;
            color: #687990;
            letter-spacing: 0;
          }
        }
      }
    }
  }
  .home-list {
    padding: 10px;
    background: #fff;
    .list-title {
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
      margin-bottom: 10px;
    }
  }
}
</style>
