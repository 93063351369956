var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c("div", { staticClass: "home" }, [
      _c(
        "div",
        { staticClass: "home-top" },
        [
          _c(
            "el-select",
            {
              staticClass: "select-project",
              attrs: { placeholder: "请选择项目" },
              model: {
                value: _vm.projectCode,
                callback: function ($$v) {
                  _vm.projectCode = $$v
                },
                expression: "projectCode",
              },
            },
            _vm._l(_vm.dict.type.project_system_code, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "home-content" }, [_vm._v(" 待定 ")]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }