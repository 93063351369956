var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.lock",
          value: _vm.fullscreenLoading,
          expression: "fullscreenLoading",
          modifiers: { lock: true },
        },
      ],
      staticClass: "app-container",
    },
    [
      _c("div", { staticClass: "head" }, [
        _c("div", { staticClass: "back" }, [
          _c("span", { staticClass: "header-back" }, [
            _c("i", {
              staticClass: "el-icon-arrow-left",
              on: { click: _vm.backPage },
            }),
          ]),
          _c("span", { staticClass: "back-title" }, [_vm._v("错误详情")]),
        ]),
        _c("div", { staticClass: "info" }, [
          _c("span", { staticClass: "msg" }, [
            _vm._v(" 错误摘要: "),
            _vm.info.level === "high"
              ? _c("span", [
                  _c("span", [_vm._v(_vm._s(_vm.logMessage.mitoMessage))]),
                ])
              : _vm.info.type === "JUMP"
              ? _c("span", [
                  _c("span", [
                    _vm._v(
                      _vm._s("跳转Pay收银台页面异常：" + _vm.logMessage.payUrl)
                    ),
                  ]),
                ])
              : _c("span", [_vm._v(_vm._s(_vm.info.message))]),
          ]),
          _c("div", { staticClass: "txt" }, [
            _c("span", { staticClass: "error" }, [
              _vm._v(_vm._s(_vm.info.name)),
            ]),
            _c("span", { staticClass: "time" }, [
              _vm._v(_vm._s(_vm.parseTime(_vm.info.time))),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "content-title" }, [_vm._v("错误明细")]),
        _c(
          "div",
          { staticClass: "detail-content" },
          [
            _c(
              "div",
              { staticClass: "info-box" },
              [
                _vm.info.level === "high"
                  ? _c("div", { staticClass: "sub-title" }, [
                      _vm._v("上报信息"),
                    ])
                  : _vm._e(),
                _vm.info.level === "high"
                  ? _c(
                      "el-row",
                      [
                        _c("el-col", { attrs: { span: 12 } }, [
                          _c("div", { staticClass: "sub-info" }, [
                            _c("span", [_vm._v("业务场景: ")]),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.selectDictLabel(
                                    _vm.dict.type.park_path_business,
                                    _vm.logMessage.path
                                  )
                                )
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "sub-info" }, [
                            _c("span", [_vm._v("车场名称: ")]),
                            _c("span", [
                              _vm._v(_vm._s(_vm.logMessage.parkName || "-")),
                            ]),
                          ]),
                        ]),
                        _c("el-col", { attrs: { span: 12 } }, [
                          _c("div", { staticClass: "sub-info" }, [
                            _c("span", [_vm._v("车牌号码: ")]),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.logMessage.inPlateNumber || "-")
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "sub-info" }, [
                            _c("span", [_vm._v("错误信息: ")]),
                            _c("span", [
                              _vm._v(_vm._s(_vm.logMessage.mitoMessage || "-")),
                            ]),
                          ]),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("div", { staticClass: "sub-title" }, [_vm._v("基本信息")]),
                _c(
                  "el-row",
                  [
                    _c("el-col", { attrs: { span: 12 } }, [
                      _vm.info.type === "JUMP"
                        ? _c("div", { staticClass: "sub-info" }, [
                            _c("span", [_vm._v("车场名称: ")]),
                            _c("span", [
                              _vm._v(_vm._s(_vm.logMessage.parkName || "-")),
                            ]),
                          ])
                        : _vm._e(),
                      _vm.info.type === "JUMP"
                        ? _c("div", { staticClass: "sub-info" }, [
                            _c("span", [_vm._v("车牌号码: ")]),
                            _c("span", [
                              _vm._v(_vm._s(_vm.logMessage.plateNumber || "-")),
                            ]),
                          ])
                        : _vm._e(),
                      _c("div", { staticClass: "sub-info" }, [
                        _c("span", [
                          _vm._v("用户ID: "),
                          _c("span"),
                          _vm._v(_vm._s(_vm.info.userId || "-")),
                        ]),
                      ]),
                      _c("div", { staticClass: "sub-info" }, [
                        _c("span", [
                          _vm._v("错误ID: "),
                          _c("span"),
                          _vm._v(_vm._s(_vm.info.errorId || "-")),
                        ]),
                      ]),
                    ]),
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c("div", { staticClass: "sub-info" }, [
                        _c("span", [_vm._v("页面URL: ")]),
                        _c("span", [_vm._v(_vm._s(_vm.info.url))]),
                      ]),
                      _c("div", { staticClass: "sub-info" }, [
                        _c("span", [_vm._v("浏览器信息: ")]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.deviceInfo.browserInfo || "-")),
                        ]),
                      ]),
                    ]),
                  ],
                  1
                ),
                JSON.stringify(_vm.deviceInfo) !== "{}"
                  ? _c("div", { staticClass: "sub-title" }, [
                      _vm._v("设备信息"),
                    ])
                  : _vm._e(),
                JSON.stringify(_vm.deviceInfo) !== "{}"
                  ? _c(
                      "el-row",
                      [
                        _c("el-col", { attrs: { span: 12 } }, [
                          _c("div", { staticClass: "sub-info" }, [
                            _c("span", [_vm._v("设备品牌: ")]),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.deviceInfo.deviceType ||
                                    "" +
                                      ((_vm.deviceInfo.brand || "-") +
                                        " " +
                                        (_vm.deviceInfo.model || "-"))
                                )
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "sub-info" }, [
                            _c("span", [_vm._v("操作系统: ")]),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.deviceInfo.system ||
                                    "" +
                                      ((_vm.deviceInfo.OS || "-") +
                                        " " +
                                        (_vm.deviceInfo.OSVersion || "-"))
                                )
                              ),
                            ]),
                          ]),
                        ]),
                        _c("el-col", { attrs: { span: 12 } }, [
                          _c("div", { staticClass: "sub-info" }, [
                            _c("span", [_vm._v("屏幕宽高: ")]),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  (_vm.deviceInfo.clientWidth || "-") +
                                    " * " +
                                    (_vm.deviceInfo.clientHeight || "-")
                                )
                              ),
                            ]),
                          ]),
                          _vm.deviceInfo.system
                            ? _c("div", { staticClass: "sub-info" }, [
                                _c("span", [_vm._v("联网类型: ")]),
                                _c("span", [
                                  _vm._v(_vm._s(_vm.deviceInfo.netType || "-")),
                                ]),
                              ])
                            : _vm._e(),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.info.type === "HTTP"
                  ? _c("div", { staticClass: "sub-title" }, [
                      _vm._v("请求信息"),
                    ])
                  : _vm._e(),
                _vm.info.type === "HTTP"
                  ? _c(
                      "el-row",
                      [
                        _c("el-col", { attrs: { span: 12 } }, [
                          _c("div", { staticClass: "sub-info" }, [
                            _c("span", [_vm._v("请求类型: ")]),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  (_vm.info.httpType || "-") +
                                    " " +
                                    (_vm.info.method || "-") +
                                    " " +
                                    (_vm.info.status || "-")
                                )
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "sub-info" }, [
                            _c("span", [_vm._v("接口URL: ")]),
                            _c("span", [_vm._v(_vm._s(_vm.info.path || "-"))]),
                          ]),
                          _c("div", { staticClass: "sub-info" }, [
                            _c("span", [_vm._v("请求耗时: ")]),
                            _c("span", [
                              _vm._v(_vm._s(_vm.info.elapsedTime) + "ms"),
                            ]),
                          ]),
                        ]),
                        _c("el-col", { attrs: { span: 12 } }, [
                          _c("div", { staticClass: "sub-info" }, [
                            _c("span", [_vm._v("入参: ")]),
                            _c("span", [
                              _vm._v(_vm._s(_vm.info.requestParam || "-")),
                            ]),
                          ]),
                          _c("div", { staticClass: "sub-info" }, [
                            _c("span", [_vm._v("返参: ")]),
                            _c("span", [
                              _vm._v(_vm._s(_vm.info.responseParam || "-")),
                            ]),
                          ]),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.info.type === "LOG" && _vm.logMessage.type === "HTTP"
                  ? _c("div", { staticClass: "sub-title" }, [
                      _vm._v("请求信息"),
                    ])
                  : _vm._e(),
                _vm.info.type === "LOG" && _vm.logMessage.type === "HTTP"
                  ? _c(
                      "el-row",
                      [
                        _c("el-col", { attrs: { span: 12 } }, [
                          _c("div", { staticClass: "sub-info" }, [
                            _c("span", [_vm._v("请求类型: ")]),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  (_vm.logMessage.method || "-") +
                                    " " +
                                    (_vm.logMessage.type === "HTTP" ? 200 : "-")
                                )
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "sub-info" }, [
                            _c("span", [_vm._v("接口URL: ")]),
                            _c("span", [
                              _vm._v(_vm._s(_vm.logMessage.path || "-")),
                            ]),
                          ]),
                        ]),
                        _c("el-col", { attrs: { span: 12 } }, [
                          _c("div", { staticClass: "sub-info" }, [
                            _c("span", [_vm._v("入参: ")]),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.logMessage.requestParam || "-")
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "sub-info" }, [
                            _c("span", [_vm._v("返参: ")]),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.logMessage.responseParam || "-")
                              ),
                            ]),
                          ]),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm.info.type === "JAVASCRIPT" || _vm.info.type === "VUE"
              ? _c(
                  "div",
                  {
                    staticClass: "content-title",
                    staticStyle: { "margin-bottom": "5px" },
                  },
                  [
                    _c("span", [_vm._v("错误堆栈")]),
                    _c(
                      "span",
                      {
                        staticClass: "special operate-btn",
                        on: { click: _vm.handleImportSourceCode },
                      },
                      [_c("i"), _vm._v(" 源码解析（SourceMap映射） ")]
                    ),
                  ]
                )
              : _vm._e(),
            _vm.info.type === "JAVASCRIPT"
              ? _c(
                  "div",
                  { staticClass: "js-stack" },
                  _vm._l(_vm.stack, function (item, index) {
                    return _c("div", { key: index, staticClass: "item" }, [
                      _c("span", { staticClass: "item-title" }, [
                        _vm._v("第" + _vm._s(index + 1) + "层"),
                      ]),
                      _c("div", { staticClass: "stack-content" }, [
                        _c("span", [_vm._v("错误信息: " + _vm._s(item.func))]),
                        _c("span", [_vm._v("文件路径: " + _vm._s(item.url))]),
                        _c("span", [_vm._v("错误参数: " + _vm._s(item.args))]),
                        _c("span", [_vm._v("代码行数: " + _vm._s(item.line))]),
                        _c("span", [
                          _vm._v("代码列数: " + _vm._s(item.column)),
                        ]),
                      ]),
                    ])
                  }),
                  0
                )
              : _vm._e(),
            _vm.info.type === "VUE"
              ? _c(
                  "div",
                  {
                    directives: [{ name: "highlight", rawName: "v-highlight" }],
                    staticClass: "vue-stack",
                  },
                  [
                    _c("pre", [
                      _vm._v("          "),
                      _c("code", [_vm._v(_vm._s(_vm.info.stack))]),
                      _vm._v("\n        "),
                    ]),
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "content-title",
                staticStyle: { "margin-bottom": "10px" },
              },
              [_vm._v("报错轨迹")]
            ),
            _c(
              "el-timeline",
              { staticClass: "breadcrumb-timeline" },
              _vm._l(_vm.breadcrumb, function (item, index) {
                return _c(
                  "el-timeline-item",
                  {
                    key: index,
                    staticClass: "item",
                    attrs: {
                      size: "large",
                      timestamp: _vm.parseTime(item.time),
                      placement: "top",
                      icon: _vm.levelMap[item.level].icon,
                      color: _vm.levelMap[item.level].color,
                    },
                  },
                  [
                    _c("el-card", { staticClass: "breadcrumb" }, [
                      _c("div", { staticClass: "breadcrumb-title" }, [
                        _c("span", { staticClass: "type-txt" }, [
                          _vm._v(
                            _vm._s(
                              _vm.selectDictLabel(
                                _vm.dict.type.browser_breadcrumb_types,
                                item.type
                              ) ||
                                _vm.selectDictLabel(
                                  _vm.dict.type.wx_breadcrumb_types,
                                  item.type
                                ) ||
                                item.type ||
                                "-"
                            )
                          ),
                        ]),
                        item.level === "error" &&
                        +item.data.errorId === +_vm.info.errorId &&
                        +item.data.time === +_vm.info.time
                          ? _c("span", { staticClass: "current-error" }, [
                              _c("span", {
                                staticClass: "styles_pulse__9HoxO",
                              }),
                              _c("span", { staticClass: "txt" }, [
                                _vm._v("当前报错位置"),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                      item.data
                        ? _c("div", { staticClass: "stack-content" }, [
                            _c(
                              "div",
                              {
                                directives: [
                                  { name: "highlight", rawName: "v-highlight" },
                                ],
                              },
                              [
                                new RegExp(/<[^>]+>/).test(item.data)
                                  ? _c("pre", [
                                      _vm._v("                  "),
                                      _c("code", { staticClass: "html" }, [
                                        _vm._v(_vm._s(item.data)),
                                      ]),
                                      _vm._v("\n                "),
                                    ])
                                  : _c("pre", [
                                      _vm._v("                  "),
                                      _c("code", [_vm._v(_vm._s(item.data))]),
                                      _vm._v("\n                "),
                                    ]),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ],
                  1
                )
              }),
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          staticClass: "upload-dialog",
          attrs: {
            title: _vm.upload.title,
            visible: _vm.upload.open,
            width: "900px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.upload, "open", $event)
            },
          },
        },
        [
          _vm.info.type === "VUE"
            ? _c(
                "div",
                {
                  directives: [{ name: "highlight", rawName: "v-highlight" }],
                  staticClass: "stack",
                },
                [
                  _c("pre", [
                    _vm._v("        "),
                    _c("code", [_vm._v(_vm._s(_vm.info.stack))]),
                    _vm._v("\n      "),
                  ]),
                ]
              )
            : _vm._e(),
          _c(
            "el-upload",
            {
              ref: "upload",
              attrs: {
                limit: 1,
                accept: ".map",
                headers: _vm.upload.headers,
                action: _vm.upload.url,
                disabled: _vm.upload.isUploading,
                "on-progress": _vm.handleFileUploadProgress,
                "on-success": _vm.handleFileSuccess,
                "auto-upload": true,
                drag: "",
              },
            },
            [
              _c("i", { staticClass: "el-icon-upload" }),
              _c("div", { staticClass: "el-upload__text" }, [
                _vm._v("将文件拖到此处，或"),
                _c("em", [_vm._v("点击上传")]),
              ]),
              _c("span", [_vm._v("仅允许导入.map文件")]),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleParse } },
                [_vm._v("确 定")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.upload.open = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }